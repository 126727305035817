import { Link, useParams, useNavigate } from 'react-router-dom';
import { Form, Row, Col, Image, ListGroup, Card, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { toast } from 'react-toastify'
import Rating from '../components/Rating';
import {
	useGetProductDetailsQuery,
	useCreateReviewMutation
} from '../slices/productsApiSlice';
import Loader from '../components/Loader';
import Message from '../components/Message';
import { addToCart } from '../slices/cartSlice';
import Meta from '../components/Meta';

const ProductScreen = () => {
	const { id: productId } = useParams();

	const [qty, setQty] = useState(1);
	const [rating, setRating] = useState(0);
	const [comment, setComment] = useState('');

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { userInfo } = useSelector(state => state.auth);

	const { data: product, isLoading, error, refetch } = useGetProductDetailsQuery(productId);
	const [createReview, { isLoading: loadingReview }] = useCreateReviewMutation();

	const addToCartHandler = () => {
		dispatch(addToCart({ ...product, qty }));
		navigate('/cart');
	}

	const submitHandler = async e => {
		e.preventDefault();
		try {
			await createReview({ productId, rating, comment }).unwrap();
			refetch();
			toast.success('Review Submitted');
	
			setRating(0);
			setComment('');
		} catch (error) {
			toast.error(error?.data?.message || error?.error);
		}
	}

	return (
		<div>
			<Link className='btn btn-light my-3 fw-bolder' to='/'>
				Go Back
			</Link>

			{isLoading ?
				(<h1><Loader /></h1>) :
				error ?
					(<Message variant='danger'>{error?.data?.message || error.error}</Message>) :
					(
						<>
						<Meta title={product.name} />
							<Row>
								<Col md='5'>
									<Image src={product.image} alt={product.name} fluid />
								</Col>
								<Col md='4'>
									<ListGroup variant='flush'>
										<ListGroup.Item>
											<h3>{product.name}</h3>
										</ListGroup.Item>
										<ListGroup.Item>
											<Rating value={product.rating} text={`${product.numReviews} reviews`} />
										</ListGroup.Item>
										<ListGroup.Item>
											<strong>Bahosi: </strong><span>{product.price} so'm</span>
										</ListGroup.Item>
										<ListGroup.Item>
											<strong>Tavsif: </strong>{product.description}
										</ListGroup.Item>
									</ListGroup>
								</Col>
								<Col md='3' >
									<Card>
										<ListGroup variant='flush'>
											<ListGroup.Item>
												<Row>
													<Col>Bahosi:</Col>
													<Col>
														<strong>{product.price} so'm</strong>
													</Col>
												</Row>
											</ListGroup.Item>
											<ListGroup.Item>
												<Row>
													<Col>Status:</Col>
													<Col>
														<strong>
															{product.countInStock > 0 ? 'Sotuvda' : "Sotuvda yo'q"}
														</strong>
													</Col>
												</Row>
											</ListGroup.Item>
											{product.countInStock > 0 && (
												<ListGroup.Item>
													<Row>
														<Col>Qty:</Col>
														<Col>
															<Form.Control as='select' value={qty} onChange={e => setQty(Number(e.target.value))}>
																{[...Array(product.countInStock).keys()].map(x => (
																	<option key={x + 1} value={x + 1}>
																		{x + 1}
																	</option>
																))}
															</Form.Control>
														</Col>
													</Row>
												</ListGroup.Item>
											)}
											<ListGroup.Item>
												<Button className='btn-block' type='button' onClick={addToCartHandler}>
													Add to card
												</Button>
											</ListGroup.Item>
										</ListGroup>
									</Card>
								</Col>
							</Row>
							<Row className='review'>
								<Col md={6}>
									<h2>Reviews</h2>
									{product.reviews.length === 0 && <Message variant='warning'>No Reviews</Message>}
									<ListGroup variant='flush'>
										{product.reviews.map((review, index) => (
											<ListGroup.Item key={index}>
												<strong>{review.name}</strong>
												<Rating value={review.rating}></Rating>
												<p>{review.createdAt.toString().substring(0, 16).replace('T', ', ')}</p>
												<p>{review.comment}</p>
											</ListGroup.Item>
										))}
										<ListGroup.Item>
											<h2>Write a customer review</h2>
											{loadingReview && <Loader />}
											{userInfo ?
												(
													<Form onSubmit={submitHandler}>
														<Form.Group controlId='rating' className='my-2'>
															<Form.Label>Rating</Form.Label>
															<Form.Control as='select' value={rating} onChange={e => setRating(Number(e.target.value))}>
																<option value="">Select...</option>
																<option value="1">1-Poor</option>
																<option value="2">2-Fair</option>
																<option value="3">3-Good</option>
																<option value="4">4-Very Good</option>
																<option value="5">5-Excellent</option>
															</Form.Control>
														</Form.Group>
														<Form.Group controlId='comment' className='my-2'>
															<Form.Label>Comment</Form.Label>
															<Form.Control as='textarea' row='3' value={comment} onChange={e => setComment(e.target.value)}>
															</Form.Control>
														</Form.Group>
														<Button disabled={loadingReview} type='submit' variant='primary' >Submit</Button>
													</Form>
												) :
												(
													<Message>Please <Link to='/login'>sign in</Link> to write a review{' '}</Message>
												)}
										</ListGroup.Item>
									</ListGroup>
								</Col>
							</Row>
						</>
					)}
		</div>
	)
}
export default ProductScreen