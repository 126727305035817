import { useEffect } from "react"
import { Row, Col, ListGroup, Image, Button, Card } from "react-bootstrap"
import { PayPalButtons, usePayPalScriptReducer } from '@paypal/react-paypal-js'
import { Link, useParams } from "react-router-dom"
import Message from "../components/Message"
import Loader from "../components/Loader"
import { useGetOrderDetailsQuery,
         usePayOrderMutation,
         useGetPayPalClientIdQuery,
        useDeliverOrderMutation } from "../slices/ordersApiSlice"
import { toast } from "react-toastify"
import { useSelector } from "react-redux"

const OrderScreen = () => {
    const { id: orderId } = useParams();

    const { 
        data: order, 
        refetch, 
        isLoading, 
        error } = useGetOrderDetailsQuery(orderId);

        const [payOrder, { isLoading:loadingPay }] = usePayOrderMutation();
        const [{ isPending }, paypalDispatch] = usePayPalScriptReducer();
        const { data: paypal, isLoading: loadingPayPal, error: errorPayPal } = useGetPayPalClientIdQuery();
        const { userInfo } = useSelector(state => state.auth);
        const [deliverOrder, { isLoading:loadingDeliverOrder }] = useDeliverOrderMutation();

        useEffect(() => {
            if (!errorPayPal &&
                !loadingPayPal &&
                paypal.clientId) {

                const loadPayPalScript = async () => {
                    paypalDispatch({
                        type: 'resetOptions',
                        value: {
                            'client-id': paypal.clientId,
                            currency: 'USD'
                        }
                    })
                    paypalDispatch({ type: 'setLoadingStatus', value: 'pending' })
                }
                if (order && !order.isPaid) {
                    if (!window.paypal) {
                        loadPayPalScript();
                    }
                }
            }
        }, [order, paypal, paypalDispatch, loadingPayPal, errorPayPal]);

        // async function onApproveTest() {
        //     await payOrder({ orderId, details: { payer: {} } });
        //     refetch();
        //     toast.success('Payment successful')
        // }

        function createOrder(data, actions) {
            return actions.order.create({
                purchase_units: [
                    { amount: { value: order.totalPrice } }
                ]
            }).then((orderId) => {
                return orderId;
            });
        }

        function onError(err) {
            toast.error(err.message);
        }

        function onApprove(data, actions) {
            return actions.order.capture().then(async function (details) {
                try {
                    await payOrder({ orderId, details });
                    refetch();
                    toast.success('Payment successful')
                } catch (error) {
                    toast.error(error?.data?.messge || error.message);
                }
            });
        }

        const deliveredOrderHandler = async () => {
            try {
                await deliverOrder(orderId);
                refetch();
                toast.success('Order delivered');
            } catch (error) {
                toast.error(error?.data?.message || error.message);
            }
        }

  return isLoading ? <Loader /> : error ? <Message variant="danger" /> :
    (
        <>
            <h1>Order: {order._id}</h1>
            <Row>
                <Col md={8}>
                    <ListGroup variant="flush">
                        <ListGroup.Item>
                            <h2>Shipping</h2>
                            <p>
                                <strong>Name: </strong> {order.user.name}
                            </p>
                            <p>
                                <strong>Email: </strong> {order.user.email}
                            </p>
                            <p>
                                <strong>Address: </strong> 
                                {order.shippingAddress.address}, {order.shippingAddress.city}{', '}
                                {order.shippingAddress.postalCode}, {order.shippingAddress.country}
                            </p>
                            { 
                                order.isDelivered ? 
                                (<Message variant="success">Delivered on {order.deliveredAt}</Message>) :
                                (<Message variant="danger">Not Delivered</Message>)
                            }
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <h2>Payment method</h2>
                            <p>
                                <strong>Method: </strong>{order.paymentMethod}
                            </p>
                            { 
                                order.isPaid ? 
                                (<Message variant="success">Paid on {order.paidAt}</Message>) :
                                (<Message variant="danger">Not Paid</Message>)
                            }
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <h2>Order Items</h2>
                            { order.orderItems.map((item, index) => (
                                <ListGroup.Item key={index}>
                                    <Row>
                                        <Col md={2}>
                                            <Image src={item.image} alt={item.name} fluid rounded />
                                        </Col>
                                        <Col md={4}>
                                            <Link to={`/product/${item.product}`}>{ item.name  }</Link> 
                                        </Col>
                                        <Col md={6}>
                                            {item.qty} x {item.price} = {item.qty * item.price} so'm
                                        </Col>
                                    </Row>
                                </ListGroup.Item>
                            )) }
                        </ListGroup.Item>
                    </ListGroup>
                </Col>
                <Col md={4}>
                    <Card>
                    <ListGroup variant='flush'>
                        <ListGroup.Item>
                            <h2>Order Summary</h2>

                        </ListGroup.Item>
                        <ListGroup.Item>
                            <Row>
                                <Col><strong>Items</strong></Col>
                                <Col>{order.itemsPrice} so'm</Col>
                            </Row>
                            <Row>
                                <Col><strong>Shipping</strong></Col>
                                <Col>{order.shippingPrice} so'm</Col>
                            </Row>
                            <Row>
                                <Col><strong>Tax</strong></Col>
                                <Col>{order.taxPrice} so'm</Col>
                            </Row>
                            <Row>
                                <Col><strong>Total</strong></Col>
                                <Col>{order.totalPrice} so'm</Col>
                            </Row>
                        </ListGroup.Item>
                        {!order.isPaid && 
                        (
                            <ListGroup.Item>
                                { loadingPay && <Loader /> }
                                { isPending ? (<Loader />) : 
                                (
                                    <div>
                                        {/* <Button onClick={ onApproveTest } style={{ marginBottom:'10px' }}>Test Pay Order</Button> */}
                                        <div>
                                            <PayPalButtons createOrder={ createOrder } onApprove={ onApprove } onError={ onError }>

                                            </PayPalButtons>
                                        </div>
                                    </div>
                                )}
                            </ListGroup.Item>
                        )}

                        { loadingDeliverOrder && <Loader /> }
                        { userInfo && userInfo.admin && order.isPaid && !order.isDelivered && 
                        (
                            <ListGroup.Item>
                                <Button type="button" className="btn btn-block" onClick={deliveredOrderHandler} >Mark as <b>DELIVERED</b></Button>
                            </ListGroup.Item>
                        ) }
                    </ListGroup>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default OrderScreen