import { PRODUCTS_URL, UPLOADS_URL } from "../constants";
import { apiSlice } from "./apiSlice";

export const productsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getProducts: builder.query({
            query: ({ pageNumber, keyword }) => ({
                url: PRODUCTS_URL,
                params: {
                    pageNumber,
                    keyword
                }
            }),
            providesTags: ['Products'],
            keepUnusedDataFor: 5
        }),
        getProductDetails: builder.query({
            query: productId => ({
                url: `${PRODUCTS_URL}/${productId}`
            }),
            keepUnusedDataFor: 5
        }),
        createProduct: builder.mutation({
            query: () => ({
                url: PRODUCTS_URL,
                method: 'POST'
            }),
            invalidatesTags: ['Products']
        }),
        updateProduct: builder.mutation({
            query: body => ({
                url: `${PRODUCTS_URL}/${body.productId}`,
                method: 'PUT',
                body
            }),
            invalidatesTags: ['Products']
        }),
        uploadProductImage: builder.mutation({
            query: body => ({
                url: UPLOADS_URL,
                method: 'POST',
                body
            })
        }),
        deleteProduct: builder.mutation({
            query: productId => ({
                url: `${PRODUCTS_URL}/${productId}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['Products']
        }),
        createReview: builder.mutation({
            query: body => ({
                url: `${PRODUCTS_URL}/${body.productId}/reviews`,
                method: 'POST',
                body
            }),
            invalidatesTags: ['Products']
        }),
        getTopProducts: builder.query({
            query: () => ({
                url: `${PRODUCTS_URL}/top`
            }),
            keepUnusedDataFor: 5
        })
    })
});

export const { 
    useGetProductsQuery, 
    useGetProductDetailsQuery,
    useCreateProductMutation,
    useUpdateProductMutation,
    useUploadProductImageMutation,
    useDeleteProductMutation,
    useCreateReviewMutation,
    useGetTopProductsQuery } = productsApiSlice;