import { LinkContainer } from 'react-router-bootstrap';
import { Table, Button } from 'react-bootstrap';
import { FaTimes, FaTrash, FaEdit, FaCheck } from 'react-icons/fa';
import { toast } from 'react-toastify'

import Message from '../../components/Message';
import Loader from '../../components/Loader';
import { 
    useGetUsersQuery, 
    useDeleteUserMutation } from '../../slices/userApiSlice';

const UserListScreen = () => {
    const { data: users, isLoading, error, refetch } = useGetUsersQuery();
    const [deleteUser, { isLoading:loadingDelete, error:deleteError }] = useDeleteUserMutation();

const deleteHandler = async _id => {
    if (window.confirm("Do you want to delete this user?")) {
        try {
            await deleteUser(_id);
            refetch();
        } catch (error) {
            toast.error(error?.data?.message || error.error);
        }
    }
}
  return (
    <div>
        <h1>Users</h1>
        { loadingDelete && <Loader /> }
        { deleteError && <Message variant='danger'>{ deleteError?.data?.message || deleteError.error }</Message> }

        { isLoading ?
            (
                <Loader />
            ) :
            error ?
            (
                <Message variant='danger'>{ error }</Message>
            ) :
            (
                <Table striped bordered hover responsive className='table-sm'>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>NAME</th>
                            <th>EMAIL</th>
                            <th>ADMIN</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        { users.map(user => (
                            <tr key={user._id}>
                                <td>{user._id}</td>
                                <td>{user.name}</td>
                                <td><a href={`mailto:${user.email}`}>{user.email}</a></td>
                                <td>{user.admin ? <FaCheck style={{color:'green'}} /> : <FaTimes style={{color:'red'}} />}</td>
                                <td>
                                    <LinkContainer to={`/admin/user/${user._id}/edit`}>
                                        <Button variant='warning' className='btn btn-sm'><FaEdit style={{color:'white'}}/></Button>
                                    </LinkContainer>{" "}
                                    <Button onClick={() => deleteHandler(user._id)} variant='danger' className='btn btn-sm'>
                                        <FaTrash style={{color:'white'}}/>
                                    </Button>
                                </td>
                            </tr>
                       ))}
                    </tbody>
                </Table>
            )
        }
    </div>
  )
}

export default UserListScreen;