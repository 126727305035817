import { LinkContainer } from 'react-router-bootstrap';
import { Table, Button, Col, Row } from 'react-bootstrap';
import { FaTrash, FaEdit } from 'react-icons/fa';
import { toast } from 'react-toastify'
import { useParams } from 'react-router-dom';

import Loader from '../../components/Loader';
import Message from '../../components/Message';
import { 
    useGetProductsQuery, 
    useCreateProductMutation,
    useDeleteProductMutation } from '../../slices/productsApiSlice';

import Paginate from '../../components/Paginate';

const ProductListScreen = () => {
    const pageNumber = useParams();
    const { data, isLoading, error} = useGetProductsQuery(pageNumber);

    const [createProduct, { isLoading: loadingCreate, error:createProductError, refetch }] = useCreateProductMutation();
    const [deleteProduct, { isLoading: loadingDelete, refetch: deleteRefetch }] = useDeleteProductMutation();

    const createProductHandler = async () => {
        if (window.confirm("Do you want to create a new product?")) {
            try {
                await createProduct();
                refetch();
            } catch (error) {
                toast.error(error?.data?.message || error.error);
            }
        }
    }

    const deleteHandler = async id => {
        if (window.confirm("Do you want to delete this product?")) {
            try {
                await deleteProduct(id);
                toast.success('Product is deleted');
                deleteRefetch();
            } catch (error) {
                toast.error(error?.data?.message || error.error);
            }
        }
    }

  return (
    <div>
        <Row className='align-items-center'>
            <Col>
                <h1>Products</h1>
            </Col>
            <Col className='text-end'>
                <Button onClick={createProductHandler} className='btn-sm m-3'><FaEdit /> Create product</Button>
            </Col>
        </Row>
        
        { loadingCreate && <Loader /> }
        { loadingDelete && <Loader /> }
        { createProductError && <Message variant='danger'>{ createProductError.error }</Message> }

        { 
            isLoading ? <Loader /> : error ? (<Message variant='danger'>{ error }</Message>) : 
            (
                <>
                <Table striped bordered hover responsive className='table-sm'>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>NAME</th>
                            <th>BRAND</th>
                            <th>CATEGORY</th>
                            <th>PRICE</th>
                            <th>INSTOCK</th>
                        </tr>
                    </thead>
                    <tbody>
                        { data.products.map(product => (
                            <tr key={product._id}>
                                <td>{product._id}</td>
                                <td>{product.name}</td>
                                <td>{product.brand}</td>
                                <td>{product.category}</td>
                                <td>{product.price} so'm</td>
                                <td>{product.countInStock}</td>
                                <td>
                                    <LinkContainer to={`/admin/product/${product._id}/edit`}>
                                        <Button variant='warning' className='btn btn-sm'>
                                            <FaEdit style={{color:'white'}} />
                                        </Button>
                                    </LinkContainer>{" "}
                                    <Button onClick={() => deleteHandler(product._id)} variant='danger' className='btn btn-sm'>
                                        <FaTrash style={{color:'white'}}/>
                                    </Button>
                                </td>
                            </tr>
                       ))}
                    </tbody>
                </Table>
                <Paginate pages={data.pages} page={data.page} isAdmin={true}></Paginate>
                </>
            )
        }
    </div>
  )
}

export default ProductListScreen