import { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import FormContainer from '../../components/FormContainer';

import Message from '../../components/Message';
import Loader from '../../components/Loader';
import { 
    useUpdateProductMutation,
    useGetProductDetailsQuery,
    // useUploadProductImageMutation,
 } from '../../slices/productsApiSlice';

const ProductEditScreen = () => {
    const {id: productId} = useParams();

    const [name, setName] = useState('');
    const [price, setPrice] = useState('');
    const [image, setImage] = useState('');
    const [brand, setBrand] = useState('');
    const [category, setCategory] = useState('');
    const [countInStock, setCountInStock] = useState(0);
    const [description, setDescription] = useState('');

    const { data: product, isLoading, error } = useGetProductDetailsQuery(productId);
    const [updateProduct, { isLoading: loadingUpdate }] = useUpdateProductMutation();
    //const [uploadProductImage, { isLoading:loadingUpload }] = useUploadProductImageMutation();

    const navigate = useNavigate();

    useEffect(() => {
        if (product) {
            setName(product.name)
            setPrice(product.price)
            setImage(product.image)
            setBrand(product.brand)
            setCategory(product.category)
            setCountInStock(product.countInStock)
            setDescription(product.description)
        }
    },[product]);

    const submitHandler = async e => {
        e.preventDefault();

        const updatedProduct = {
            productId, name, price,
            image, brand, category,
            countInStock, description
        }
        const result = await updateProduct(updatedProduct);
        
        if (result.error) {
            toast.error(result.error);
        } else {
            toast.success('Product updated');
            navigate('/admin/productlist');
        }
    }

    // const uploadFileHandler = async e => {
    //     const formData = new FormData();
    //     formData.append('image', e.target.files[0]);
    //     try {
    //         const res = await uploadProductImage(formData).unwrap();
    //         toast.success(res.message);
    //         setImage(res.image);
    //     } catch (error) {
    //         toast.error(error?.data?.message || error.error);
    //     }
    // }

  return (
    <div>
        <Link to='/admin/productlist' className='btn btn-light my-2'><b>Go back</b></Link>
        <FormContainer>
            <h1>Edit product</h1>
            { loadingUpdate && <Loader /> }
            {/* { loadingUpload && <Loader /> } */}
            {
                isLoading ? <Loader /> : error ? <Message variant='danger'>{ error }</Message> :
                (
                    <Form onSubmit={submitHandler}>
                        <Form.Group controlId='name'>
                            <Form.Label>Name</Form.Label>
                            <Form.Control type='text' placeholder='Enter name' value={name} onChange={e => setName(e.target.value)}></Form.Control>
                        </Form.Group>
                        <Form.Group className='my-2' controlId='price'>
                            <Form.Label>Price</Form.Label>
                            <Form.Control min={1} type='number' value={price} onChange={e => setPrice(e.target.value)}></Form.Control>
                        </Form.Group>
                        <Form.Group className='my-2' controlId='image'>
                            <Form.Label>Image</Form.Label>
                            {/* <Form.Control type='file' name='image' label='Choose image' onChange={uploadFileHandler} ></Form.Control> */}
                        </Form.Group>
                        <Form.Group className='my-2' controlId='brand'>
                            <Form.Label>Brand</Form.Label>
                            <Form.Control type='text' placeholder='Enter brand' value={brand} onChange={e => setBrand(e.target.value)}></Form.Control>
                        </Form.Group>
                        <Form.Group className='my-2' controlId='category'>
                            <Form.Label>Category</Form.Label>
                            <Form.Control type='text' placeholder='Enter category' value={category} onChange={e => setCategory(e.target.value)}></Form.Control>
                        </Form.Group>
                        <Form.Group className='my-2' controlId='instock'>
                            <Form.Label>InStock</Form.Label>
                            <Form.Control min={1} type='number' value={countInStock} onChange={e => setCountInStock(e.target.value)}></Form.Control>
                        </Form.Group>
                        <Form.Group className='my-2' controlId='description'>
                            <Form.Label>Description</Form.Label>
                            <Form.Control as="textarea" rows={3} placeholder='Enter description' value={description} onChange={e => setDescription(e.target.value)}></Form.Control>
                        </Form.Group>
                        <Button type='submit' variant='primary' className='my-2'>Update</Button>
                    </Form>
                )
            }
        </FormContainer>
    </div>
  )
}

export default ProductEditScreen