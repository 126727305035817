import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Form, Button, Col } from 'react-bootstrap';
import FormContainer from '../components/FormContainer';
import CheckoutSteps from '../components/CheckoutSteps';
import { savePaymentMethod } from '../slices/cartSlice';

const PaymentScreen = () => {
    const [paymentMethod, setPaymentMethod] = useState('PayPal');

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { shippingAddress } = useSelector(state => state.cart);

    useEffect(() => {
        if (!shippingAddress) navigate('/shipping');
    }, [shippingAddress, navigate]);

    const submitHandler = e => {
        e.preventDefault();
        dispatch(savePaymentMethod(paymentMethod));
        navigate('/placeorder');
    }

  return (
    <FormContainer>
        <CheckoutSteps step1 step2 step3 />
        <h1>Payment Method</h1>
        <Form onClick={submitHandler}>
            <Form.Group>
                <Form.Label>Select Method</Form.Label>
                <Col>
                    <Form.Check 
                                type='radio' 
                                className='my-2' 
                                label='Paypal or creditcard' 
                                name='paymentMethod' 
                                value={'PayPal'} 
                                checked 
                                onChange={e => setPaymentMethod(e.target.value)}>
                    </Form.Check>
                </Col>
            </Form.Group>
            <Button type='submit' variant='primary'>Continue</Button>
        </Form>
    </FormContainer>

  )
}

export default PaymentScreen