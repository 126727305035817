import { Helmet } from 'react-helmet-async'

const Meta = ({ title, description, keywords }) => {
  return (
    <div>
        <Helmet>
            <title>{ title }</title>
            <meta name="description" content={description} />
            <meta name="keywords" content={keywords} />
        </Helmet>
    </div>
  )
}

Meta.defaultProps = {
    title: 'Welcome to TeachHub',
    description: 'We sell the best programming presentations for cheap',
    keywords: 'presentation, programming, cheap'
}

export default Meta