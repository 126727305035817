import { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import FormContainer from '../../components/FormContainer';

import Message from '../../components/Message';
import Loader from '../../components/Loader';
import { useGetUserDetailsQuery, 
         useUpdateUserMutation } from '../../slices/userApiSlice';

const UserEditScreen = () => {
    const {id: userId} = useParams();

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [admin, setAdmin] = useState(false);

    const { data: user, isLoading, error, refetch } = useGetUserDetailsQuery(userId);
    const [updateUser, { isLoading: loadingUpdate }] = useUpdateUserMutation();

    const navigate = useNavigate();

    useEffect(() => {
        if (user) {
            setName(user.name)
            setEmail(user.email)
            setAdmin(user.admin)
        }
    },[user]);

    const submitHandler = async e => {
        e.preventDefault();        
        
        try {
            await updateUser({ userId, name, email, admin });
            toast.success('User updated');
            refetch();
            navigate('/admin/userlist');
        } catch (error) {
            toast.error(error);
        }
    }

  return (
    <div>
        <Link to='/admin/userlist' className='btn btn-light my-2'><b>Go back</b></Link>
        <FormContainer>
            <h1>Edit user</h1>
            { loadingUpdate && <Loader /> }

            { 
                isLoading ? <Loader /> : error ? <Message variant='danger'>{ error }</Message> :
                (
                    <Form onSubmit={submitHandler}>
                        <Form.Group controlId='name'>
                            <Form.Label>Name</Form.Label>
                            <Form.Control type='text' placeholder='Enter name' value={name} onChange={e => setName(e.target.value)}></Form.Control>
                        </Form.Group>
                        <Form.Group className='my-2' controlId='email'>
                            <Form.Label>Email</Form.Label>
                            <Form.Control type='text' placeholder='Enter email' value={email} onChange = { e => setEmail(e.target.value)}></Form.Control>
                        </Form.Group>
                        <Form.Group className='my-2' controlId='admin'>
                            <Form.Label>Admin</Form.Label>
                            <Form.Control type='text' placeholder='isAdmin' value={admin} onChange={e => setAdmin(e.target.value)}></Form.Control>
                        </Form.Group>
                        <Button type='submit' variant='primary' className='my-2'>Update</Button>
                    </Form>
                )
            }
        </FormContainer>
    </div>
  )
}

export default UserEditScreen