import { Button } from 'react-bootstrap';
import { FaDownload } from 'react-icons/fa';
import axios from 'axios';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';

const PurchaseScreen = () => {
   const { name, id } = useParams();
   const [progress, setProgress] = useState(0);

   const downloadFile = name => {
      const fileUrl = `/api/orders/download/${name}/${id}`; // Replace with your file URL
      axios({
         url: fileUrl,
         method: 'GET',
         responseType: 'blob', // Important for binary data
         onDownloadProgress: (progressEvent) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setProgress(percentCompleted);
         },
      })
         .then((response) => {
            // Create a Blob from the response data
            const blob = new Blob([response.data], { type: 'application/octet-stream' });
            // Create a temporary URL for the Blob
            const url = window.URL.createObjectURL(blob);
            // Trigger the download
            const link = document.createElement('a');
            link.href = url;
            link.download = `${name}.pptx`; // Set the desired filename
            link.click();
         }).catch(err => {
            if (err.code === 'ERR_BAD_REQUEST') {
               toast.error(`${name}.pptx not found`);
            } else {
               toast.error(`Server error`);
            }
         });
   };
   return (
      <div className='text-center'>
         <h1>{ name }.pptx</h1>
         <Button className='m-5' onClick={() => downloadFile(name, id)}>DOWNLOAD <FaDownload /></Button>
         
         <div className="progress">
            <div
               className="progress-bar"
               role="progressbar"
               style={{ width: `${progress}%` }}
               aria-valuenow={progress}
               aria-valuemin="0"
               aria-valuemax="100">
               { progress }%
            </div>
         </div>
      </div>
   )
}

export default PurchaseScreen