import { Row, Col } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Product from '../components/Product';
import { useGetProductsQuery } from '../slices/productsApiSlice';
import Loader from '../components/Loader';
import Message from '../components/Message';
import Paginate from '../components/Paginate';
import ProductCarusel from '../components/ProductCarusel';

const HomeScreen = () => {
    const pageNumber = useParams();
    const { data, isLoading, error } = useGetProductsQuery(pageNumber);

    return (
        <>
        { !pageNumber.keyword ? (<ProductCarusel />) : 
        (
            <Link to='/' className='btn btn-light mb-2'>
                <b>Back</b>
            </Link>
        )}
            { isLoading ? (<h1><Loader /></h1>) : error ? (<Message variant='danger'>{error?.data?.message || error.error}</Message>) : (<>
                { pageNumber.keyword ? <h1>Search result:</h1> : <h1>Products</h1> }
                <Row>
                    { data?.products?.map((product) => (
                        <Col sm={12} md={6} lg={4} xl={3} key={product._id}>
                            <Product product={product} />
                        </Col>
                    ))}
                </Row>
                <Paginate pages={data.pages} page={data.page} keyword={pageNumber.keyword ? pageNumber.keyword : ''} />
            </>) }
        </>
    )
}

export default HomeScreen;