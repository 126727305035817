export const addDecimals = num => (Math.round(num * 100) / 100).toFixed(2);

export const  updateCart = state => {
        // calc items price 
        state.itemsPrice = addDecimals(state.cartItems.reduce((acc, item) => item.price * item.qty + acc, 0));
    
        // calc shipping price (if order is over $100 than free, else $10 shipping)
        state.shippingPrice = addDecimals(state.itemsPrice > 100 ? 0 : 10);

        // calc tax price (15% tax)
        state.taxPrice = addDecimals(Number((state.itemsPrice * 0.15).toFixed(2)));

        // calc total price
        state.totalPrice = (
                Number(state.itemsPrice) +
                Number(state.shippingPrice) +
                Number(state.taxPrice)).toFixed(2);

        localStorage.setItem('cart', JSON.stringify(state));

        return state;
}