import { Table, Image } from 'react-bootstrap';
import { FaDownload, FaTimes } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import Message from '../components/Message';
import Loader from '../components/Loader';
import { useGetMyOrdersQuery } from "../slices/ordersApiSlice";

const PurchaseScreen = () => {
   const { data: orders, isLoading, error } = useGetMyOrdersQuery();
   return (
      <div>
         {isLoading ?
            (
               <Loader />
            ) : error ?
               (
                  <Message variant='danger'>{error?.data?.message || error.error}</Message>
               ) : orders.length === 0 ? <h2 className='text-center'>Empty</h2> :
               (
                  <Table striped hover responsive className='table-sm'>
                     <thead>
                        <tr>
                           <th>Image</th>
                           <th>Name</th>
                           <th>DELIVERED</th>
                           <th>Download</th>
                        </tr>
                     </thead>
                     <tbody>
                        {orders.map(order => (
                           order.orderItems.map(item => (
                              <tr key={item._id}>
                                 <td><Image alt={item.name} src={item.image} style={{width:'70px'}} rounded/></td>
                                 <td>{item.name}</td>
                                 <td>{order.isDelivered ? (order.deliveredAt.toString().substring(0, 16).replace('T', ', ')) : (<FaTimes style={{ color: 'red' }} />)}</td>
                                 <td>
                                    {order.isDelivered ? 
                                    (
                                       <Link to={`/download/${item.name}/${order._id}`}>
                                          <FaDownload />
                                       </Link>
                                    ) : 
                                    (<FaTimes style={{ color: 'red' }} />) }
                                 </td>
                              </tr>
                           ))
                        ))}
                     </tbody>
                  </Table>
               )
         }
      </div>
   )
}

export default PurchaseScreen