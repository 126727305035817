import { useState, useEffect } from 'react';
import { Table, Form, Button, Row, Col } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { FaTimes } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import Message from '../components/Message';
import Loader from '../components/Loader';
import { toast } from 'react-toastify';
import { setCredentials } from '../slices/authSlice';
import { useProfileMutation } from '../slices/userApiSlice';
import { useGetMyOrdersQuery } from '../slices/ordersApiSlice';

const ProfileScreen = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const dispatch = useDispatch();

    const { userInfo } = useSelector(state => state.auth);

    const [updateProfile, { isLoading:loadingUserProfile }] = useProfileMutation();
    const {data: orders, isLoading, error:errorMyOrder } = useGetMyOrdersQuery();

    useEffect(() => {
        if (userInfo) {
            setName(userInfo.name);
            setEmail(userInfo.email);
        }
    }, [userInfo, userInfo.name, userInfo.email]);

    const submitHandler = async e => {
        e.preventDefault();
        
        if (password !== confirmPassword) 
            toast.error('Password do not match');
        else 
            try {
                const res = await updateProfile({ _id:userInfo._id, name, email, password }).unwrap();
                dispatch(setCredentials(res));
                toast.success('Profile updated successfully');
            } catch (error) {
                toast.error(error?.data?.message || error?.error);
            }
        
    }

  return (
    <>
        <Row>
            <Col md={3}>
                <h2>User profile</h2>

                <Form onSubmit={submitHandler}>
                    <Form.Group controlId='name' className='my-2'>
                        <Form.Label>Name</Form.Label>
                        <Form.Control type='name' placeholder='Enter name' value={name} onChange={e => setName(e.target.value)} />
                    </Form.Group>
                    <Form.Group controlId='email' className='my-2'>
                        <Form.Label>Email</Form.Label>
                        <Form.Control type='email' placeholder='Enter email' value={email} onChange={e => setEmail(e.target.value)} />
                    </Form.Group>
                    <Form.Group controlId='password' className='my-2'>
                        <Form.Label>Password</Form.Label>
                        <Form.Control type='password' placeholder='Enter password' value={password} onChange={e => setPassword(e.target.value)} />
                    </Form.Group>
                    <Form.Group controlId='confirmPassword' className='my-2'>
                        <Form.Label>Confirm password</Form.Label>
                        <Form.Control type='password' placeholder='Enter confirm password' value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} />
                    </Form.Group>
                    <Button type='submit'>Update</Button>
                    { loadingUserProfile && <Loader /> }
                </Form>
            </Col>
            <Col md={9}>
                <h2>My orders</h2>
                { isLoading ? 
                    (
                        <Loader />
                    ) : errorMyOrder ? 
                    (
                        <Message variant='danger'>{ errorMyOrder?.data?.message || errorMyOrder.error }</Message>
                    ) :
                    (
                        <Table striped hover responsive className='table-sm'>
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>DATE</th>
                                    <th>TOTAL</th>
                                    <th>PAID</th>
                                    <th>DELIVERED</th>
                                </tr>
                            </thead>
                            <tbody>
                                { orders.map(order => (
                                    <tr key={order._id}>
                                        <td>{ order._id }</td>
                                        <td>{ order.createdAt.substring(0, 10) }</td>
                                        <td>{ order.totalPrice } so'm</td>
                                        <td>{ order.isPaid ? (order.paidAt.substring(0, 10)) : (<FaTimes style={{color:'red'}} />) }</td>
                                        <td>{ order.isDelivered ? (order.deliveredAt.substring(0, 10)) : (<FaTimes style={{color:'red'}} />) }</td>
                                        <td>
                                            <LinkContainer to={`/order/${order._id}`}>
                                                <Button className='btn-sm' variant='light'>Details</Button>
                                            </LinkContainer>
                                        </td>
                                    </tr>
                                )) }
                            </tbody>
                        </Table>
                    )
                }
            </Col>
        </Row>
    </>
  )
}

export default ProfileScreen