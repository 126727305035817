import { useNavigate } from 'react-router-dom';
import { Badge, Navbar, Nav, Container, NavDropdown } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { FaShoppingCart, FaUser } from 'react-icons/fa';
import { useSelector, useDispatch } from 'react-redux';

import { useLogoutMutation } from '../slices/userApiSlice';
import { logout } from '../slices/authSlice';
import logo from '../assets/headLogoWhite.png';

import SearchBox from './SearchBox';

const Header = () => {
    const { cartItems } = useSelector(state => state.cart);
    const { userInfo } = useSelector(state => state.auth);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [logoutApiCall] = useLogoutMutation();

    const logoutHandle = async () => {
        try {
            await logoutApiCall().unwrap();
            dispatch(logout());
            navigate('/login');
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <header>
            <Navbar bg='dark' variant='dark' expand='md' collapseOnSelect>
                <Container>
                    <LinkContainer to='/'>
                        <Navbar.Brand>
                            <img src={logo} style={{width:'30px'}} alt='logo' />
                            TeachHub
                        </Navbar.Brand>                    
                    </LinkContainer>
                    <Navbar.Toggle aria-controls='basic-navbar-nav' />
                    <Navbar.Collapse id='basic-navbar-id'>
                        <Nav className='ms-auto'>
                            <LinkContainer to='/cart'>
                                <Nav.Link><FaShoppingCart /> Cart {cartItems.length > 0 && (
                                    <Badge pill bg='warning' style={{marginLeft:'5px'}}>
                                        { cartItems.reduce((count, item) => count + item.qty, 0) }
                                    </Badge>
                                )}</Nav.Link>
                            </LinkContainer>
                            { userInfo ? 
                                (
                                    <NavDropdown title={userInfo.name} id='username'>
                                        <LinkContainer to='/profile'>
                                            <NavDropdown.Item>Profile</NavDropdown.Item>
                                        </LinkContainer>
                                        <LinkContainer to='/purchases'>
                                            <NavDropdown.Item>Purchases</NavDropdown.Item>
                                        </LinkContainer>
                                        <NavDropdown.Item onClick={logoutHandle}>
                                            Logout
                                        </NavDropdown.Item>
                                    </NavDropdown>
                                ) :
                                <LinkContainer to='/login'>
                                    <Nav.Link><FaUser /> Sign In</Nav.Link>
                                </LinkContainer>
                            }
                            { userInfo && userInfo.admin &&
                                (
                                    <NavDropdown title='Admin' id='adminmenu'>
                                        <LinkContainer to='/admin/productlist'>
                                            <NavDropdown.Item>Products</NavDropdown.Item>
                                        </LinkContainer>
                                        <LinkContainer to='/admin/userlist'>
                                            <NavDropdown.Item>Users</NavDropdown.Item>
                                        </LinkContainer>
                                        <LinkContainer to='/admin/orderlist'>
                                            <NavDropdown.Item>Orders</NavDropdown.Item>
                                        </LinkContainer>
                                    </NavDropdown>
                                )
                            }
                            <SearchBox />
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </header>
    );
}

export default Header;
